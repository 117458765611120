import React, { useState, useEffect } from "react";

import SsmDashboard from "./pages/indexSSM";
import DefaultDashboard from "./pages/default";
import TemplateComponent3 from "../../Components/Common/TemplateComponent3";
import TemplateComponent from "../../Components/Common/TemplateComponent";
import {
  getDashboard,
  resetDashboardState,
} from "../../store/dashboard/action";

import {
  getCompany,
  resetCompanyState,
} from "../../store/company/action";

import { useSelector, useDispatch } from "react-redux";
import TemplateSmartWeight from "../../Components/Common/templateSmartWeight";
import TemplateKppRantau from "../../Components/Common/templateKppRantau";

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const [tempId, setTempId] = useState();
  const [mapList, setMapList] = useState("");
  const [userType, setCompanyId] = useState("");
  const [userId, setUserId] = useState("");
  const [isApi, setIsApi] = useState(false)


  const { dashboards, companies } = useSelector((state) => ({
    dashboards: state.Dashboard.dashboards,
    companies: state.Company.companies
  }));



  useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    setUserId(obj.data.userId);
    if (obj) {
      dispatch(resetDashboardState());
      dispatch(getDashboard({ companyId: obj.data.companyId }));
      dispatch(resetCompanyState())
      dispatch(getCompany({ clientId: obj.data.clientId }))
    } else {
      window.location = "/logout";
    }
  }, [dispatch]);

  const [mapApiKey, setMapApiKey] = useState("")

  useEffect(() => {
    if (companies) {
      console.log(companies, "comp");
      sessionStorage.setItem("mapApiKey", companies[0]?.mapApiKey + "&loading=async");
      setMapApiKey(companies[0]?.mapApiKey)
    }
  }, [companies])



  useEffect(() => {
    if (dashboards[0] && dashboards[0].dsbTemplateId) {
      setTempId(dashboards[0] && dashboards[0].dsbTemplateId);
    }
  }, [dashboards]);

  useEffect(() => {
    if (dashboards) {
      let dataMap;
      console.log(dashboards, 'dash');
      dashboards.forEach((dashboard) => {
        setCompanyId(dashboards.clientId);
        dashboard.dashboard_detail.forEach((detail) => {
          if (detail.type === "map") {
            dataMap = detail.value;
          }
        });
      });
      setMapList(dataMap);
    }
  }, [dashboards]);

  document.title = "Dashboard | TELKOMSEL";
  return JSON.parse(sessionStorage.getItem("authUser")).data.companyId === 8 ? (
    <SsmDashboard
      clientId={JSON.parse(sessionStorage.getItem("authUser")).data.clientId}
      isload={false}
      isloaddefault={false}
    />
  ) : JSON.parse(sessionStorage.getItem("authUser")).data.companyId === 14 ? (
    <TemplateComponent3
      isEdit={false}
      dashboards={dashboards}
      mapLists={mapList}
    />
  ) : JSON.parse(sessionStorage.getItem("authUser")).data.branchId === 112 ? (
    <TemplateKppRantau />
  ) : JSON.parse(sessionStorage.getItem("authUser")).data.companyId === 2 ? (
    <TemplateComponent
      isEdit={false}
      dashboards={dashboards}
      mapLists={mapList}
    />
  ) : JSON.parse(sessionStorage.getItem("authUser")).data.companyId === 21 ? (
    <TemplateSmartWeight />
  ) : (
    <DefaultDashboard
      clientId={JSON.parse(sessionStorage.getItem("authUser")).data.clientId}
      mapApiKey={mapApiKey}
      props={props}
    />
  );
};

export default Dashboard;
