import React, { useEffect, useState, useCallback } from "react";
import { Card, CardBody, Col, Row, Spinner } from "reactstrap";
import moment from "moment";
import "moment-timezone";
import DeleteModal from "../../Components/Common/DeleteModal";
import { isEmpty } from "lodash";

import Masonry from "react-responsive-masonry";

//redux
import { useSelector, useDispatch } from "react-redux";

// import {
//   GoogleMap,
//   Marker,
//   InfoWindow,
// } from "@react-google-maps/api";
//Import actions
import { deleteDashboardTemplateDetail as onDeleteDashboardTemplateDetail } from "../../store/dashboardTemplateDetail/action";
import { getDevice, resetDeviceState } from "../../store/device/action";
import { getHistory, resetHistoryState } from "../../store/history/action";
import "react-toastify/dist/ReactToastify.css";

import defaultImg from "../../assets/images/upload.png";
import device from "../../assets/images/device.jpeg";
import { api } from "../../config";
import WidgetSingle from "./WidgetSingle";
import WidgetList from "./WidgetList";
import WidgetGauge from "./WidgetGauge";
import WidgetTank from "./WidgetTank";
import ChartDonut from "./ChartDonut";
import ChartBar from "./ChartBar";

import { PortfolioCharts2 } from "../../pages/Dashboard/DashboardCharts";
import ModalInputTemplate from "./ModalInputTemplate";

import socketIO from "../../helpers/socket";
import AlertWidget from "./AlertWidget";
import BreadCrumbTemplate from "./BreadCrumbTemplate";
import PropTypes from "prop-types";
import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { extendMoment } from "moment-range";

const mapStyles = {
  width: "100%",
  height: "47vh",
};

const center = {
  lat: -6.2240456,
  lng: 118.0148634,
};

// const libraries = ["drawing", "marker", "places", "geometry"];

const LoadingContainer = () => <div>Loading...</div>;
const io = socketIO.getInstance();
// io.setHost("https://socket.atapteknologi.id");
const socket = io.getSocket();
const Template = (props) => {
  const moments = extendMoment(moment);
  // const [isScriptLoaded, setScriptLoaded] = useState(false);
  // const [isScriptError, setScriptError] = useState(false);
  const { t } = props;
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [uidEvents, setUidEvents] = useState([]);
  const [dataUid, setDataUid] = useState([]);
  const [markerList, setMarkerList] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [deviceSocket, setDeviceSocket] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const [modal, setModal] = useState(false);
  const [logo, setLogo] = useState(defaultImg);
  const dispatch = useDispatch();
  const [isPan, setIsPan] = useState(false);
  const [donutList, setDonutList] = useState([]);
  const [barValList, setBarValList] = useState([]);
  const [widgetList, setWidgetList] = useState([]);
  const [mapList, setMapList] = useState([]);
  const [barList, setBarList] = useState([]);
  const [chartData, setchartData] = useState([0, 0]);
  const [groupData, setGroupData] = useState([]);
  const [socketActive, setSocketActive] = useState(0)

  const {
    dashboardTemplateDetails,
    isDashboardTemplateDetailCreated,
    isDashboardTemplateDetailDeleted,
    isDashboardTemplateDetailSuccess,
    loading,
    error,
    clients,
    isDashboardSuccess,
    histories,
    dashboardLoading,
    devices,
    historyPublics
  } = useSelector((state) => ({
    dashboardTemplateDetails:
      state.DashboardTemplateDetail.dashboardTemplateDetails,
    isDashboardTemplateDetailCreated:
      state.DashboardTemplateDetail.isDashboardTemplateDetailCreated,
    isDashboardTemplateDetailSuccess:
      state.DashboardTemplateDetail.isDashboardTemplateDetailSuccess,
    isDashboardTemplateDetailDeleted:
      state.DashboardTemplateDetail.isDashboardTemplateDetailDeleted,
    loading: state.DashboardTemplateDetail.loading,
    error: state.DashboardTemplateDetail.error,
    clients: state.Client.clients,
    isDashboardSuccess: state.Dashboard.isDashboardSuccess,
    histories: state.History.histories,
    dashboardLoading: state.Dashboard.loading,
    devices: state.Device.devices,
    historyPublics: state.History.historyPublics,
  }));

  const [dashboardTemplateDetail, setDashboardTemplateDetail] = useState([]);
  const [asTotalCheck, setAsTotalCheck] = useState(false);

  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [roles, setRoles] = useState(null);
  const [userType, setUserType] = useState("user");
  const [series, setSeries] = useState([]);
  const [seriesCategories, setSeriesCategories] = useState([]);
  const [labels, setLabels] = useState([]);
  const [map, setMap] = useState();
  const [type, setType] = useState("");
  const [clientId, setClientId] = useState();
  const [companyId, setCompanyId] = useState();
  const [color, setColor] = useState("#fff");
  const [clientName, setClientName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [testDaily, setTestDaily] = useState([]);
  const [infoWindowData, setInfoWindowData] = useState();
  const [isRealTime, setIsRealTime] = useState(false)

  const handleMarkerClick = (id, lat, lng, address) => {
    setMapRef({ lat, lng });
    setInfoWindowData({ id, address });
    setIsOpen(true);
  };

  function onConnect() {
    setIsConnected(true);
  }

  function onDisconnect() {
    setIsConnected(false);
  }

  useEffect(() => {
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
  });

  const onUidEvent = useCallback(
    (value) => {
      const val = JSON.parse(value);
      const data = uidEvents.filter((item) => item.uid !== val.uid);
      data.push(val);
      setUidEvents(data);
    },
    [uidEvents]
  );

  useEffect(() => {
    deviceSocket.forEach((element) => {
      socket.on(element.identifier, onUidEvent);
    });

    return () => {
      deviceSocket.forEach((element) => {
        socket.off(element.identifier, onUidEvent);
      });
    };
  }, [uidEvents, deviceSocket, onUidEvent]);

  useEffect(() => {
    if (!isEmpty(devices)) {
      let deviceList = [];
      let data = [];
      devices.forEach((device) => {
        deviceList.push(device)
        data.push(device.identifier)
      });
      setDataUid(data);
      setDeviceSocket(deviceList)
    }
  }, [devices]);

  const handleValidDate = (date) => {
    return moment(date).tz("Asia/Jakarta").format("MMM D, YYYY HH:mm");
  };

  useEffect(() => {
    if (dataUid.length > 0 && !isRealTime) {
      dispatch(resetHistoryState());
      dispatch(getHistory({ type: "current", uid: JSON.stringify(dataUid) }));
    }
  }, [dispatch, dataUid, isRealTime]);

  useEffect(() => {
    if (histories.length > 0 && props.dashboards) {
      let counter = 0;
      props.dashboards.forEach((dash, dashIndex) => {
        dash.dashboard_detail.forEach((det, detIndex) => {
          histories.forEach((element) => {
            if (element) {
              if (det.dd_device.name === element.name) {
                det.dd_parameter.forEach((param, paramIndex) => {
                  console.log(param, 'params');
                  if (element.message) {
                    const amount = element.message[param.ddp_parameter.value];
                    if (amount) {
                      props.dashboards[dashIndex].dashboard_detail[
                        detIndex
                      ].dd_parameter[paramIndex].ddp_parameter.amount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    } else if (amount === 0) {
                      props.dashboards[dashIndex].dashboard_detail[
                        detIndex
                      ].dd_parameter[paramIndex].ddp_parameter.amount = "0";
                    }
                  }
                });
              }
            }
          });
        });
      });
      let dataDev = []
      deviceSocket.forEach((device, deviceIdx) => {
        dataDev.push(device)
        histories.forEach((element) => {
          if (device.identifier === element.uid) {
            if (element.message) {
              const start = new Date(element.message.timestamp);
              const end = new Date();
              const range1 = moments.range(start, end);
              const toMinute = range1.snapTo("minute");
              deviceSocket[deviceIdx].toMinute = toMinute.diff("minutes")
            }
            if (element && element.message) {
              if ((new Date().getTime() - new Date(element.device_time).getTime()) < 1800000) {
                counter++;
                deviceSocket.forEach((dl, n) => {
                  if (dl.identifier === element.uid) deviceSocket[n].isOnline = true;
                })
              }
            }
          }
        });
      });
      setMarkers(dataDev)
      setIsRealTime(true)
      setSocketActive(counter);
      setchartData([deviceSocket.length - counter, counter]);
      setIsDone(true);
    }
  }, [
    histories,
    props.dashboards,
    deviceSocket,
    moments
  ]);

  useEffect(() => {
    if (isDone) {
      props.dashboards.forEach((dash, dashIndex) => {
        dash.dashboard_detail.forEach((det, detIndex) => {
          uidEvents.forEach((element) => {
            if (element) {
              if (det.dd_device.name === element.name) {
                det.dd_parameter.forEach((param, paramIndex) => {
                  if (element.message) {
                    const amount = element.message[param.ddp_parameter.value];
                    if (amount) {
                      props.dashboards[dashIndex].dashboard_detail[
                        detIndex
                      ].dd_parameter[paramIndex].ddp_parameter.amount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    } else if (amount === 0) {
                      props.dashboards[dashIndex].dashboard_detail[
                        detIndex
                      ].dd_parameter[paramIndex].ddp_parameter.amount = "0";
                    }
                  }
                });
              }
            }
          });
        });
      });
      console.log(uidEvents, "Has Changed");
    }
  }, [uidEvents, props.dashboards, isDone]);

  useEffect(() => {
    if (isDone && uidEvents && deviceSocket) {
      let data = [];
      let counter = 0;
      deviceSocket.forEach((dev, n) => {
        uidEvents.forEach((element) => {
          if (dev.identifier === element.uid && (new Date().getTime() - new Date(element.device_time).getTime()) < 1800000) {
            data.push(dev);
            counter++;
            deviceSocket[n].isOnline = true;
          }
        });
      });

      const jum = deviceSocket.filter(obj => {
        return obj.isOnline;
      })
      setSocketActive(jum.length);
      setchartData([deviceSocket.length - jum.length, jum.length]);
    }
  }, [uidEvents, deviceSocket, isDone]);

  const onClickDelete = (dashboardTemplateDetail) => {
    setDashboardTemplateDetail(dashboardTemplateDetail);
    setDeleteModal(true);
  };

  const handleDeleteDashboardTemplateDetail = () => {
    if (dashboardTemplateDetail) {
      dispatch(onDeleteDashboardTemplateDetail(dashboardTemplateDetail));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    if (obj) {
      if (obj.data && obj.data.userType) setUserType(obj.data.userType);

      if (obj.data.userType === "company" || obj.data.userType === "user") {
        setCompanyId(obj.data.companyId);
        setClientId(obj.data.clientId);
        setClientName(obj.data.client_name);
        setCompanyName(obj.data.company_name);
      } else if (obj.data.userType === "client") {
        setClientId(obj.data.clientId);
        setClientName(obj.data.client_name);
      } else if (obj.data.userType === "admin") {
        setCompanyId(obj.data.companyId);
      }
    } else {
      window.location = "/logout";
    }
  }, [dispatch]);

  useEffect(() => {
    if (barValList) {
      let dataLabel = [];
      for (const data of barValList) {
        for (const dataPar of data.dd_parameter) {
          dataLabel.push({
            label: dataPar.ddp_parameter.label,
            value: dataPar.ddp_parameter.value,
          });
        }
      }
      setLabels(dataLabel);
    }
  }, [barValList]);

  useEffect(() => {
    const start = new Date();
    const times = 24;

    let arrCat = [];
    for (let i = 0; i < times; i++) {
      const toPrint = moment(start)
        .add(60 * i, "minutes")
        .format("YYYY-MM-DD HH:mm");

      arrCat.push(toPrint);
    }
    setSeriesCategories(arrCat);
  }, []);

  useEffect(() => {
    if (!isSearch) {
      dispatch(resetDeviceState());
      dispatch(getDevice({ status: 2 }));
    }
    setIsSearch(true);
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    if (obj) {
      if (
        obj.data &&
        obj.data.employee_position &&
        obj.data.employee_position.position_role
      )
        setRoles(obj.data.employee_position.position_role.role_method);
      else setRoles(null);
      if (obj.data && obj.data.userType) setUserType(obj.data.userType);
      if (obj.data.userType !== "admin") {
        if (obj.data && obj.data.clientId) setClientId(obj.data.clientId);
        if (obj.data && obj.data.companyId) setCompanyId(obj.data.companyId);
      }
    } else {
      window.location = "/logout";
    }
  }, [dispatch, dashboardTemplateDetails, isSearch, userType, companyId]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setDashboardTemplateDetail(null);
      setLogo(defaultImg);
      setAsTotalCheck(false);
      setType("");
    } else {
      setModal(true);
    }
  }, [modal]);

  const handleDashboardDetailClick = useCallback(
    (arg) => {
      const dashboardTemplateDetail = arg;

      setType(dashboardTemplateDetail.type);
      setColor(dashboardTemplateDetail.bgColor);

      let data_params = [];
      if (dashboardTemplateDetail.dd_parameter) {
        dashboardTemplateDetail.dd_parameter.forEach((item) => {
          const label = item.ddp_parameter?.label;
          const id = item.ddp_parameter?.id;
          var obj = { label: label, value: id };
          data_params.push(obj);
        });
      }

      setCompanyId(dashboardTemplateDetail.companyId);
      setClientId(dashboardTemplateDetail.clientId);
      setAsTotalCheck(dashboardTemplateDetail.asTotal);

      setDashboardTemplateDetail({
        id: dashboardTemplateDetail.id,
        name: dashboardTemplateDetail.name,
        type: dashboardTemplateDetail.type,
        image: dashboardTemplateDetail.image,
        value: {
          label:
            dashboardTemplateDetail.value.charAt(0).toUpperCase() +
            dashboardTemplateDetail.value.substr(1),
          value: dashboardTemplateDetail.value,
        },
        description: dashboardTemplateDetail.description,
        min: dashboardTemplateDetail.min,
        max: dashboardTemplateDetail.max,
        asTotal: dashboardTemplateDetail.asTotal,
        sort_order: dashboardTemplateDetail.sort_order,
        column: dashboardTemplateDetail.column,
        period: {
          label: dashboardTemplateDetail.period
            ? dashboardTemplateDetail.period.charAt(0).toUpperCase() +
            dashboardTemplateDetail.period.substr(1)
            : "",
          value: dashboardTemplateDetail.period
            ? dashboardTemplateDetail.period
            : "",
        },
        dashboardId: dashboardTemplateDetail.dashboardId,
        device: {
          label: dashboardTemplateDetail.dd_device.name,
          value: dashboardTemplateDetail.dd_device.id,
        },
        params: data_params,
        client: {
          label: dashboardTemplateDetail.clientId,
          value: dashboardTemplateDetail.clientId,
        },
        company: {
          label: dashboardTemplateDetail.companyId,
          value: dashboardTemplateDetail.companyId,
        },
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  useEffect(() => {
    if (props.dashboards && isDashboardSuccess) {
      let dataChart = [];
      let dataWidget = [];
      let dataMap = [];
      let dataBar = [];
      let barVal = [];
      props.dashboards.forEach((dashboard) => {
        dashboard.dashboard_detail.forEach((detail) => {
          if (detail.value === "donut") {
            dataChart.push(detail);
          } else if (detail.value === "line") {
            barVal.push(detail);
          } else if (detail.type === "widget") {
            dataWidget.push(detail);
          } else if (detail.type === "map") {
            dataMap = detail;
          } else if (detail.type === "bar") {
            dataBar.push(detail);
          }
        });
      });
      setDonutList(dataChart);
      setWidgetList(dataWidget);
      setMapList(dataMap);
      setBarList(dataBar);
      setBarValList(barVal);
    }
  }, [props.dashboards, isDashboardSuccess]);

  useEffect(() => {
    if (barValList) {
      let data = [];
      barValList.forEach((line) => {
        if (line.period === "daily") {
          data.push(line.dd_device.uid);
        }
      });
      setTestDaily(JSON.stringify(data));
    }
  }, [barValList]);

  // useEffect(() => {
  //   if (!isEmpty(clients)) {
  //     let apiKey = sessionStorage.getItem("mapApiKey")
  //     if (apiKey) {
  //       const script = document.createElement('script');
  //       script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=${libraries.join(',')}`;
  //       script.async = true;
  //       script.onload = () => setScriptLoaded(true);
  //       script.onerror = () => setScriptError(true);

  //       document.body.appendChild(script);

  //       return () => {
  //         document.body.removeChild(script);
  //       };
  //     }
  //   }
  // }, [clients]);

  // if (!isScriptLoaded) {
  //   return <div>Loading...</div>;
  // }

  // if (isScriptError) {
  //   return <div>Failed to load map. Please check your internet connection and try again.</div>
  // }

  document.title = `${t("Dashboard")} | TELKOMSEL - IOT Monitoring`;
  return (
    <div className='page-content'>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteDashboardTemplateDetail}
        onCloseClick={() => setDeleteModal(false)}
      />
      <BreadCrumbTemplate
        dashboards={props.dashboards}
        clients={clients}
        userType={userType}
        isDashboardTemplateDetailCreated={isDashboardTemplateDetailCreated}
        isDashboardTemplateDetailDeleted={isDashboardTemplateDetailDeleted}
        t={t}
      />
      {dashboardLoading ? (
        <>
          <Spinner
            color='primary'
            type='grow'
            size={"sm"}
            className='ms-2'></Spinner>
          &nbsp;
          <Spinner color='success' type='grow' size={"sm"}>
            Loading...
          </Spinner>
          &nbsp;
        </>
      ) : (
        ""
      )}
      <Row className='mb-2'>
        <Col lg={7}>
          <Row>
            <Col lg={12}>
              {props.isEdit ? (
                <div className='d-flex justify-content-end mb-3'>
                  <button
                    type='button'
                    className='btn btn-sm btn-success add-btn me-2'
                    id='create-btn'
                    onClick={() => {
                      toggle();
                      setType("map");
                      setIsEdit(false);
                    }}>
                    <i className='ri-map-pin-line me-1 align-bottom fs-14'></i>{" "}
                    {t("Setting Map")}
                  </button>
                  {mapList ? (
                    <>
                      <button
                        type='button'
                        className='btn btn-sm btn-warning add-btn me-2'
                        id='create-btn'
                        onClick={() => {
                          handleDashboardDetailClick(mapList);
                        }}>
                        <i className='ri-pencil-line  align-bottom fs-14'></i>
                      </button>
                      <button
                        type='button'
                        className='btn btn-sm btn-danger add-btn'
                        id='create-btn'
                        onClick={() => {
                          const data = mapList;
                          onClickDelete(data);
                        }}>
                        <i className='ri-delete-bin-line  align-bottom fs-14'></i>
                      </button>
                    </>
                  ) : null}
                </div>
              ) : null}
              {/* {props.mapLists ? (
                <Card style={markers.length > 0 ? { height: "51vh" } : { height: "55vh" }}>
                  <CardBody>
                    {markers.length > 0 ? null : (<Spinner color='success' size={"sm"}>
                      Loading...
                    </Spinner>)}
                    <div
                      id='gmaps-markers'
                      className='gmaps'
                      style={{ position: "relative" }}>
                      <GoogleMap
                        mapContainerStyle={mapStyles}
                        zoom={isOpen ? 12 : 4}
                        center={isOpen ? mapRef : center}
                        options={{
                          mapTypeId: props.mapLists,
                          mapTypeControl: false,
                          fullscreenControl: false,
                          streetViewControl: false,
                        }}
                        onLoad={(map) => setMap(map)}
                        onClick={() => setIsOpen(false)}>
                        {(markers || []).map((mark, markIdx) => {
                          return (
                            <div key={markIdx}>
                              <Marker
                                position={{
                                  lat: parseFloat(mark.latitude),
                                  lng: parseFloat(mark.longitude),
                                }}
                                title={mark.name}
                                icon={{
                                  url:
                                    mark.device_service &&
                                      mark.device_service.name ===
                                      "Fuel Management System"
                                      ? require("../../assets/images/map-tank.png")
                                      : mark.device_service &&
                                        mark.device_service.name ===
                                        "Power Monitoring"
                                        ? require("../../assets/images/map-power.png")
                                        : mark.device_service &&
                                          mark.device_service.name ===
                                          "Vessel Monitoring System"
                                          ? require("../../assets/images/map-vessel.png")
                                          : mark.device_service &&
                                            mark.device_service.name ===
                                            "Smart Water Monitoring"
                                            ? require("../../assets/images/map-water.png")
                                            : mark.device_service &&
                                              mark.device_service.name ===
                                              "Engine Monitoring"
                                              ? require("../../assets/images/map-engine.png")
                                              : mark.device_service &&
                                                mark.device_service.name ===
                                                "Energy Monitoring System"
                                                ? require("../../assets/images/map-lighting.png")
                                                : mark.device_service &&
                                                  mark.device_service.name ===
                                                  "Temperature Monitoring"
                                                  ? require("../../assets/images/map-weather.png")
                                                  : mark.device_service &&
                                                    mark.device_service.name ===
                                                    "Gps Tracking"
                                                    ? require("../../assets/images/map-gps.png")
                                                    : null,
                                  scaledSize: new window.google.maps.Size(
                                    32,
                                    50
                                  ),
                                }}
                                onClick={() => {
                                  handleMarkerClick(
                                    markIdx,
                                    parseFloat(mark.latitude),
                                    parseFloat(mark.longitude),
                                    mark.name
                                  );
                                  setIsOpen(true);
                                }}>
                                {isOpen &&
                                  infoWindowData?.id === markIdx ? (
                                  <InfoWindow
                                    onCloseClick={() => {
                                      setIsOpen(false);
                                    }}>
                                    <div>
                                      <div className='d-flex'>
                                        <div className='justify-content-start'>
                                          <h3 className='text-primary'>
                                            {mark.name}
                                          </h3>
                                          <p className='m-0'>
                                            Location: {mark.latitude},{" "}
                                            {mark.longitude}
                                          </p>
                                          <p className='m-0'>
                                            UUID: {mark.uid}
                                          </p>
                                          <p className='m-0 mb-3'>
                                            Phone: {mark.phone}
                                          </p>
                                        </div>
                                      </div>
                                      <img
                                        alt=''
                                        src={
                                          mark.image
                                            ? api.MEDIA_URL +
                                            "/media/" +
                                            mark.image
                                            : defaultImg
                                        }
                                        className='img-thumbnail'
                                        width='300'
                                      />
                                    </div>
                                  </InfoWindow>
                                ) : null}
                              </Marker>
                            </div>
                          );
                        })}
                      </GoogleMap>
                    </div>
                  </CardBody>
                </Card>
              ) : null} */}
            </Col>
            <Col lg={12}>
              {props.isEdit ? (
                <div className='d-flex justify-content-end mb-3'>
                  <button
                    type='button'
                    className='btn btn-sm btn-success add-btn'
                    id='create-btn'
                    onClick={() => {
                      toggle();
                      setType("chart");
                      setIsEdit(false);
                    }}>
                    <i className='ri-bar-chart-box-line me-1 align-bottom fs-14'></i>{" "}
                    {`${t("Add")} ${t("Chart")}`}
                  </button>
                </div>
              ) : null}
              {donutList.map((chart, idxChart) => {
                let label = [];
                let seriess = [];
                chart.dd_parameter.forEach((params) => {
                  label.push(params.ddp_parameter.label);
                  if (params.ddp_parameter.amount) {
                    seriess.push(params.ddp_parameter.amount);
                  } else {
                    seriess.push(1);
                  }
                });
                return (
                  <Card key={"chart" + idxChart}>
                    <CardBody>
                      <Row>
                        <Col lg={12}>
                          {chart ? (
                            <>
                              <div className='d-flex align-item-center'>
                                <div className='flex-grow-1'>
                                  <h3>{chart.name}</h3>
                                </div>
                                {props.isEdit ? (
                                  <div className='flex-shrink-0'>
                                    <button
                                      type='button'
                                      className='btn btn-sm btn-warning add-btn me-2'
                                      id='create-btn'
                                      onClick={() => {
                                        handleDashboardDetailClick(chart);
                                      }}>
                                      <i className='ri-pencil-line  align-bottom fs-14'></i>
                                    </button>
                                    <button
                                      type='button'
                                      className='btn btn-sm btn-danger add-btn'
                                      id='create-btn'
                                      onClick={() => {
                                        const data = chart;
                                        onClickDelete(data);
                                      }}>
                                      <i className='ri-delete-bin-line  align-bottom fs-14'></i>
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </>
                          ) : null}
                          {chart.value === "donut" ? (
                            <ChartDonut
                              data={chart}
                              label={label}
                              series={seriess}
                            />
                          ) : null}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                );
              })}

              {barValList.map((chartBar, idxChartBar) => {
                let label = [];
                let seriess = [];
                let data = [];
                chartBar.dd_parameter.forEach((params) => {
                  let obj = { name: params.ddp_parameter.label };
                  let arr = [];
                  seriesCategories.forEach((cat) => {
                    arr.push(Math.round(1 + Math.random() * (120 - 1)));
                  });
                  obj.data = arr;
                  data.push(obj);
                });
                return (
                  <Card key={"chartbar" + idxChartBar}>
                    <CardBody>
                      <Row>
                        <Col lg={12}>
                          {chartBar ? (
                            <div className='d-flex align-item-center'>
                              <div className='flex-grow-1'>
                                <h3>{chartBar.name}</h3>
                              </div>
                              {props.isEdit ? (
                                <div className='flex-shrink-0'>
                                  <button
                                    type='button'
                                    className='btn btn-sm btn-warning add-btn me-2'
                                    id='create-btn'
                                    onClick={() => {
                                      handleDashboardDetailClick(chartBar);
                                    }}>
                                    <i className='ri-pencil-line  align-bottom fs-14'></i>
                                  </button>
                                  <button
                                    type='button'
                                    className='btn btn-sm btn-danger add-btn'
                                    id='create-btn'
                                    onClick={() => {
                                      const data = chartBar;
                                      onClickDelete(data);
                                    }}>
                                    <i className='ri-delete-bin-line  align-bottom fs-14'></i>
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                          {chartBar.value === "line" && isRealTime ? (
                            <ChartBar
                              data={chartBar}
                              isRealTime={isRealTime}
                            />
                          ) : null}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                );
              })}
            </Col>
          </Row>
        </Col>
        <Col lg={5}>
          {props.isEdit ? (
            <div className='d-flex justify-content-end mb-3'>
              <button
                type='button'
                className='btn btn-sm btn-success add-btn'
                id='create-btn'
                onClick={() => {
                  toggle();
                  setType("widget");
                  setIsEdit(false);
                }}>
                <i className='ri-stack-fill me-1 align-bottom fs-14'></i>{" "}
                {`${t("Add")} ${t("Widget")}`}
              </button>
            </div>
          ) : null}
          <Card>
            <CardBody>
              <div className='d-flex'>
                <div className='avatar-md mt-3 ms-3'>
                  <span className='bg-light'>
                    <img src={device} alt='' height='50px' width='50px' />
                  </span>
                </div>
                <div className='flex-grow-1 ms-3'>
                  <div className='text-center'>
                    <h1 className='mt-4'>{t("Total Devices")}</h1>
                  </div>
                </div>
                <div className='mb-0 text-muted flex-shrink-0'>
                  <div className='d-inline-flex'>
                    <div className='mt-4'>
                      <p className='fs-16 mb-0 text-muted'>
                        <i className='mdi mdi-circle fs-10 align-middle text-info me-1'></i>{" "}
                        {socketActive} {t("ON")}
                      </p>
                      <p className='fs-16 mb-0 text-muted'>
                        <i className='mdi mdi-circle fs-10 align-middle text-primary me-1'></i>{" "}
                        {deviceSocket.length - socketActive} {t("OFF")}
                      </p>
                    </div>
                    <PortfolioCharts2
                      series={chartData}
                      labelText='Total'
                      labelSize='12px'
                      labelData={["OFF", "ON"]}
                      dataWidth={130}
                      dataColors='["--vz-primary", "--vz-info", "--vz-warning", "--vz-success"]'
                    />
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>

          {widgetList.length > 0 ? (
            <Masonry
              columnsCount={widgetList.length === 1 ? 1 : 2}
              gutter='25px'>
              {widgetList.map((widget, idxWidget) => {
                return (
                  <div key={"widget" + idxWidget}>
                    <Card className='mb-0'>
                      <CardBody>
                        {props.isEdit ? (
                          <div className='d-flex justify-content-center mb-3'>
                            <button
                              type='button'
                              className='btn btn-sm btn-warning add-btn me-2'
                              id='create-btn'
                              onClick={() => {
                                handleDashboardDetailClick(widget);
                              }}>
                              <i className='ri-pencil-line  align-bottom fs-14'></i>
                            </button>
                            <button
                              type='button'
                              className='btn btn-sm btn-danger add-btn'
                              id='create-btn'
                              onClick={() => {
                                const data = widget;
                                onClickDelete(data);
                              }}>
                              <i className='ri-delete-bin-line  align-bottom fs-14'></i>
                            </button>
                          </div>
                        ) : null}
                        {widget.value === "single" ? (
                          <WidgetSingle data={widget} />
                        ) : widget.value === "list" ? (
                          <WidgetList data={widget} />
                        ) : widget.value === "gauge" ? (
                          <WidgetGauge data={widget} dev={[]} />
                        ) : widget.value === "tank" ? (
                          <WidgetTank
                            data={widget}
                            isEdit={props.isEdit}
                            comp={1}
                          />
                        ) : null}
                      </CardBody>
                    </Card>
                  </div>
                );
              })}
              <AlertWidget t={t} />
            </Masonry>
          ) : null}
        </Col>
      </Row>
      <ModalInputTemplate
        modal={modal}
        toggle={toggle}
        isEdit={isEdit}
        userType={userType}
        clientId={clientId}
        clientName={clientName}
        companyId={companyId}
        companyName={companyName}
        type={type}
        dashboardTemplateDetail={dashboardTemplateDetail}
        t={t}
      />
      {/* <ToastContainer closeButton={false} /> */}

    </div>
  );
};

Template.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Template));
