import React, { useEffect, useState, useCallback } from "react";
import { Card, CardBody, Col, Row, Spinner } from "reactstrap";
import moment from "moment";
import "moment-timezone";
import DeleteModal from "../../Components/Common/DeleteModal";
import { isEmpty } from "lodash";

import Masonry from "react-responsive-masonry";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
//Import actions
import { deleteDashboardTemplateDetail as onDeleteDashboardTemplateDetail } from "../../store/dashboardTemplateDetail/action";
import { getClient, resetClientState } from "../../store/client/action";
import { getHistory, resetHistoryState } from "../../store/history/action";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import defaultImg from "../../assets/images/upload.png";
import { api } from "../../config";
import WidgetSingle from "./WidgetSingle";
import WidgetList from "./WidgetList";
import WidgetGauge from "./WidgetGauge";
import WidgetTank from "./WidgetTank";
import ModalInputTemplate from "./ModalInputTemplate";

import pinTank from "../../assets/images/oil-tank.png";
import pinPower from "../../assets/images/tower.png";
import device from "../../assets/images/device.jpeg";
import socketIO from "../../helpers/socket";
import AlertWidget from "./AlertWidget";
import BreadCrumbTemplate from "./BreadCrumbTemplate";
import { PortfolioCharts2 } from "../../pages/Dashboard/DashboardCharts";
import PropTypes from "prop-types";
import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";

const mapStyles = {
  width: "100%",
  height: "47vh",
};

const center = {
  lat: -6.2240456,
  lng: 118.0148634,
};

const libraries = ["drawing", "marker", "places", "geometry"];

const LoadingContainer = () => <div>Loading...</div>;
const io = socketIO.getInstance();
// io.setHost("https://socket.atapteknologi.id");
const socket = io.getSocket();
const Template2 = (props) => {
  const [mapApiKey, setMapApiKey] = useState(sessionStorage.getItem("mapApiKey"))
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey:
      mapApiKey,
    libraries,
  });
  const { t } = props;
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [uidEvents, setUidEvents] = useState([]);
  const [dataUid, setDataUid] = useState("[]");
  const [markerList, setMarkerList] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [deviceSocket, setDeviceSocket] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const [modal, setModal] = useState(false);
  const [logo, setLogo] = useState(defaultImg);
  const dispatch = useDispatch();
  const [isPan, setIsPan] = useState(false);
  const [barValList, setBarValList] = useState([]);
  const [widgetList, setWidgetList] = useState([]);
  const [mapList, setMapList] = useState([]);
  const [chartData, setchartData] = useState([0, 0]);
  const [socketActive, setSocketActive] = useState([])

  const {
    dashboardTemplateDetails,
    isDashboardTemplateDetailCreated,
    isDashboardTemplateDetailDeleted,
    isDashboardTemplateDetailSuccess,
    loading,
    error,
    clients,
    isDashboardSuccess,
    histories,
    dashboardLoading,
  } = useSelector((state) => ({
    dashboardTemplateDetails:
      state.DashboardTemplateDetail.dashboardTemplateDetails,
    isDashboardTemplateDetailCreated:
      state.DashboardTemplateDetail.isDashboardTemplateDetailCreated,
    isDashboardTemplateDetailSuccess:
      state.DashboardTemplateDetail.isDashboardTemplateDetailSuccess,
    isDashboardTemplateDetailDeleted:
      state.DashboardTemplateDetail.isDashboardTemplateDetailDeleted,
    loading: state.DashboardTemplateDetail.loading,
    error: state.DashboardTemplateDetail.error,
    parameters: state.Parameter.parameters,
    clients: state.Client.clients,
    isDashboardSuccess: state.Dashboard.isDashboardSuccess,
    histories: state.History.histories,
    dashboardLoading: state.Dashboard.loading,
  }));

  const [dashboardTemplateDetail, setDashboardTemplateDetail] = useState([]);
  const [templates, setTemplates] = useState("");
  const [asTotalCheck, setAsTotalCheck] = useState(false);

  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [roles, setRoles] = useState(null);
  const [userType, setUserType] = useState("user");
  const [labels, setLabels] = useState([]);
  const [map, setMap] = useState();
  const [type, setType] = useState("");
  const [clientId, setClientId] = useState();
  const [companyId, setCompanyId] = useState();
  const [color, setColor] = useState("#fff");
  const [clientName, setClientName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [infoWindowData, setInfoWindowData] = useState();

  const handleMarkerClick = (id, lat, lng, address) => {
    setMapRef({ lat, lng });
    setInfoWindowData({ id, address });
    setIsOpen(true);
  };

  function onConnect() {
    setIsConnected(true);
  }

  function onDisconnect() {
    setIsConnected(false);
  }

  useEffect(() => {
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
  });

  const onUidEvent = useCallback(
    (value) => {
      const val = JSON.parse(value);
      const data = uidEvents.filter((item) => item.uid !== val.uid);
      data.push(val);
      setUidEvents(data);
    },
    [uidEvents]
  );

  useEffect(() => {
    deviceSocket.forEach((element) => {
      socket.on(element.identifier, onUidEvent);
    });

    return () => {
      deviceSocket.forEach((element) => {
        socket.off(element.identifier, onUidEvent);
      });
    };
  }, [uidEvents, deviceSocket, onUidEvent]);

  useEffect(() => {
    if (!isEmpty(clients)) {
      let deviceList = [];
      let data = [];
      clients.forEach((client, i) => {
        client.cl_company.forEach((company) => {
          company.com_branch.forEach((branch) => {
            branch.br_unit.forEach((unit) => {
              if (
                unit.companyId === props.dashboards[0]?.dashboard_company.id
              ) {
                unit.un_device.forEach((device) => {
                  if (device.device_status.id === 2) {
                    deviceList.push(device);
                    data.push(device.identifier);
                  }
                });
              }
            });
          });
        });
      });
      setDataUid(JSON.stringify(data));
      setDeviceSocket(deviceList);
    }
  }, [clients, props.dashboards]);

  useEffect(() => {
    if (clients && dataUid) {
      dispatch(resetHistoryState());
      dispatch(getHistory({ type: "real_time", uid: dataUid }));
    }
  }, [clients, dispatch, dataUid]);

  const handleValidDate = (date) => {
    return moment(date).tz("Asia/Jakarta").format("MMM D, YYYY HH:mm");
  };

  useEffect(() => {
    if (!isEmpty(clients)) {
      let unitArr = [];
      let deviceArr = [];
      let branchArr = [];
      clients.forEach((client, i) => {
        client.cl_company.forEach((company) => {
          branchArr = [...branchArr, company.com_branch];
          company.com_branch.forEach((branch) => {
            unitArr = [...unitArr, ...branch.br_unit];
          });
        });
      });
      setGroupData(unitArr);
    }
  }, [clients]);

  useEffect(() => {
    if (histories.length > 0 && props.dashboards) {
      props.dashboards.forEach((dash, dashIndex) => {
        dash.dashboard_detail.forEach((det, detIndex) => {
          histories.forEach((element) => {
            if (det.dd_device.uid === element.uid) {
              det.dd_parameter.forEach((param, paramIndex) => {
                if (element.message) {
                  const amount = element.message[param.ddp_parameter.value];
                  if (element.device_time) {
                    element.message.datetime = handleValidDate(
                      element.device_time
                    );
                    element.message.device_time = handleValidDate(
                      element.device_time
                    );
                  }
                  if (amount) {
                    props.dashboards[dashIndex].dashboard_detail[
                      detIndex
                    ].dd_parameter[paramIndex].ddp_parameter.amount = amount;
                  } else if (amount === 0) {
                    props.dashboards[dashIndex].dashboard_detail[
                      detIndex
                    ].dd_parameter[paramIndex].ddp_parameter.amount = "0";
                  }
                }
              });
            }
          });
        });
      });
    } else {
      let dataMark = [];
      groupData.forEach((group, groupIndex) => {
        group.un_device.forEach((device, deviceIndex) => {
          if (device.device_status.id === 2) {
            dataMark.push(device);
            setMarkers(dataMark);
          }
          uidEvents.forEach((element) => {
            if (device.uid === element.uid) {
              device.latitude = element.gps?.latitude;
              device.longitude = element.gps?.longitude;
              device.device_parameter &&
                device.device_parameter.forEach((parameter, parameterIndex) => {
                  if (element.message) {
                    const amount = element.message[parameter.value];
                    if (
                      parameter.value === "device_time" &&
                      element.message.device_time
                    ) {
                      var date1 = new Date(parameter.amount);
                      var date2 = new Date(element.device_time);
                      if (date1.getTime() > date2.getTime()) {
                        element.message.device_time = parameter.amount;
                      } else {
                        element.message.device_time = element.device_time;
                      }
                    }
                    if (
                      parameter.value === "datetime" &&
                      element.message.datetime
                    ) {
                      var date3 = new Date(parameter.amount);
                      var date4 = new Date(element.device_time);
                      if (date3.getTime() > date4.getTime()) {
                        element.message.datetime = parameter.amount;
                      } else {
                        element.message.datetime = element.device_time;
                      }
                    }
                    if (amount) {
                      groupData[groupIndex].un_device[
                        deviceIndex
                      ].device_parameter[parameterIndex].amount = amount;
                    } else if (amount === 0) {
                      groupData[groupIndex].un_device[
                        deviceIndex
                      ].device_parameter[parameterIndex].amount = "0";
                    }
                  }
                });
            }
          });
        });
      });
    }
    setIsDone(true);
  }, [
    histories,
    props.dashboards,
    markerList,
    props,
    map,
    groupData,
    uidEvents,
  ]);

  useEffect(() => {
    if (isDone) {
      let dataMark = [];
      props.dashboards.forEach((dash, dashIndex) => {
        dash.dashboard_detail.forEach((det, detIndex) => {
          uidEvents.forEach((element) => {
            if (det.dd_device.uid === element.uid) {
              if (
                det.dd_device.device_status &&
                det.dd_device.device_status.id === 2
              ) {
                dataMark.push(device);
                setMarkers(dataMark);
              }
              det.dd_parameter.forEach((param, paramIndex) => {
                if (element.message) {
                  const amount = element.message[param.ddp_parameter.value];
                  if (
                    param.value === "device_time" &&
                    element.message.device_time
                  ) {
                    var date1 = new Date(param.amount);
                    var date2 = new Date(element.device_time);
                    if (date1.getTime() > date2.getTime()) {
                      element.message.device_time = param.amount;
                    } else {
                      element.message.device_time = element.device_time;
                    }
                  }
                  if (param.value === "datetime" && element.message.datetime) {
                    var date3 = new Date(param.amount);
                    var date4 = new Date(element.device_time);
                    if (date3.getTime() > date4.getTime()) {
                      element.message.datetime = param.amount;
                    } else {
                      element.message.datetime = element.device_time;
                    }
                  }
                  if (element.device_time) {
                    element.message.datetime = handleValidDate(
                      element.device_time
                    );
                    element.message.device_time = handleValidDate(
                      element.device_time
                    );
                  }
                  if (amount) {
                    props.dashboards[dashIndex].dashboard_detail[
                      detIndex
                    ].dd_parameter[paramIndex].ddp_parameter.amount = amount;
                  } else if (amount === 0) {
                    props.dashboards[dashIndex].dashboard_detail[
                      detIndex
                    ].dd_parameter[paramIndex].ddp_parameter.amount = "0";
                  }
                }
              });
            }
          });
        });
      });
    }
  }, [uidEvents, props.dashboards, isDone, markerList, props, map]);

  const onClickDelete = (dashboardTemplateDetail) => {
    setDashboardTemplateDetail(dashboardTemplateDetail);
    setDeleteModal(true);
  };

  const handleDeleteDashboardTemplateDetail = () => {
    if (dashboardTemplateDetail) {
      dispatch(onDeleteDashboardTemplateDetail(dashboardTemplateDetail));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    if (obj) {
      if (obj.data && obj.data.userType) setUserType(obj.data.userType);

      if (obj.data.userType === "company" || obj.data.userType === "user") {
        setCompanyId(obj.data.companyId);
        setClientId(obj.data.clientId);
        setClientName(obj.data.client_name);
        setCompanyName(obj.data.company_name);
      } else if (obj.data.userType === "client") {
        setClientId(obj.data.clientId);
        setClientName(obj.data.client_name);
      } else if (obj.data.userType === "admin") {
        setCompanyId(obj.data.companyId);
      }
    } else {
      window.location = "/logout";
    }
  }, [dispatch]);

  useEffect(() => {
    if (barValList) {
      let dataLabel = [];
      for (const data of barValList) {
        for (const dataPar of data.dd_parameter) {
          dataLabel.push({
            label: dataPar.ddp_parameter.label,
            value: dataPar.ddp_parameter.value,
          });
        }
      }
      setLabels(dataLabel);
    }
  }, [barValList]);

  useEffect(() => {
    if (!isSearch) {
      dispatch(resetClientState());
      dispatch(getClient({ status: 2 }));
    }
    setIsSearch(true);
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    if (obj) {
      if (
        obj.data &&
        obj.data.employee_position &&
        obj.data.employee_position.position_role
      )
        setRoles(obj.data.employee_position.position_role.role_method);
      else setRoles(null);
      if (obj.data && obj.data.userType) setUserType(obj.data.userType);
      if (obj.data.userType !== "admin") {
        if (obj.data && obj.data.clientId) setClientId(obj.data.clientId);
        if (obj.data && obj.data.companyId) setCompanyId(obj.data.companyId);
      }
    } else {
      window.location = "/logout";
    }
  }, [
    dispatch,
    dashboardTemplateDetails,
    isSearch,
    templates,
    userType,
    companyId,
  ]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setDashboardTemplateDetail(null);
      setLogo(defaultImg);
      setAsTotalCheck(false);
      setType("");
    } else {
      setModal(true);
    }
  }, [modal]);

  const handleDashboardDetailClick = useCallback(
    (arg) => {
      const dashboardTemplateDetail = arg;

      setType(dashboardTemplateDetail.type);
      setColor(dashboardTemplateDetail.bgColor);

      let data_params = [];
      if (dashboardTemplateDetail.dd_parameter) {
        dashboardTemplateDetail.dd_parameter.forEach((item) => {
          const label = item.ddp_parameter?.label;
          const id = item.ddp_parameter?.id;
          var obj = { label: label, value: id };
          data_params.push(obj);
        });
      }

      setCompanyId(dashboardTemplateDetail.companyId);
      setClientId(dashboardTemplateDetail.clientId);
      setAsTotalCheck(dashboardTemplateDetail.asTotal);

      setDashboardTemplateDetail({
        id: dashboardTemplateDetail.id,
        name: dashboardTemplateDetail.name,
        type: dashboardTemplateDetail.type,
        image: dashboardTemplateDetail.image,
        value: {
          label:
            dashboardTemplateDetail.value.charAt(0).toUpperCase() +
            dashboardTemplateDetail.value.substr(1),
          value: dashboardTemplateDetail.value,
        },
        description: dashboardTemplateDetail.description,
        min: dashboardTemplateDetail.min,
        max: dashboardTemplateDetail.max,
        asTotal: dashboardTemplateDetail.asTotal,
        sort_order: dashboardTemplateDetail.sort_order,
        column: dashboardTemplateDetail.column,
        period: {
          label: dashboardTemplateDetail.period
            ? dashboardTemplateDetail.period.charAt(0).toUpperCase() +
            dashboardTemplateDetail.period.substr(1)
            : "",
          value: dashboardTemplateDetail.period
            ? dashboardTemplateDetail.period
            : "",
        },
        dashboardId: dashboardTemplateDetail.dashboardId,
        device: {
          label: dashboardTemplateDetail.dd_device.name,
          value: dashboardTemplateDetail.dd_device.id,
        },
        params: data_params,
        client: {
          label: dashboardTemplateDetail.clientId,
          value: dashboardTemplateDetail.clientId,
        },
        company: {
          label: dashboardTemplateDetail.companyId,
          value: dashboardTemplateDetail.companyId,
        },
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  useEffect(() => {
    if (props.dashboards && isDashboardSuccess) {
      let dataChart = [];
      let dataWidget = [];
      let dataMap;
      let dataBar = [];
      let barVal = [];
      props.dashboards.forEach((dashboard) => {
        dashboard.dashboard_detail.forEach((detail) => {
          if (detail.value === "donut") {
            dataChart.push(detail);
          } else if (detail.value === "bar") {
            barVal.push(detail);
          } else if (detail.type === "widget") {
            dataWidget.push(detail);
          } else if (detail.type === "map") {
            dataMap = detail;
          } else if (detail.type === "bar") {
            dataBar.push(detail);
          }
        });
      });
      setWidgetList(dataWidget);
      if (dataMap && dataMap.value) {
        setMapList(dataMap);
      }
      setBarValList(barVal);
    }
  }, [props.dashboards, isDashboardSuccess]);

  useEffect(() => {
    if (uidEvents && deviceSocket) {
      let data = [];
      deviceSocket.forEach((dev) => {
        uidEvents.forEach((element) => {
          if (dev.identifier === element.uid) {
            data.push(dev);
          }
        });
      });
      const result = data.reduce((accumulator, current) => {
        let exists = accumulator.find((item) => {
          return item.identifier === current.identifier;
        });
        if (!exists) {
          accumulator = accumulator.concat(current);
        }
        return accumulator;
      }, []);
      setSocketActive(result);
      setchartData([deviceSocket.length - result.length, result.length]);
    }
  }, [uidEvents, deviceSocket]);

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  document.title = `${t("Dashboard")} | TELKOMSEL - IOT Monitoring`;
  return (
    <div className='page-content'>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteDashboardTemplateDetail}
        onCloseClick={() => setDeleteModal(false)}
      />
      <BreadCrumbTemplate
        dashboards={props.dashboards}
        clients={clients}
        userType={userType}
        isDashboardTemplateDetailCreated={isDashboardTemplateDetailCreated}
        isDashboardTemplateDetailDeleted={isDashboardTemplateDetailDeleted}
        t={t}
      />
      {dashboardLoading ? (
        <>
          <Spinner
            color='primary'
            type='grow'
            size={"sm"}
            className='ms-2'></Spinner>
          &nbsp;
          <Spinner color='success' type='grow' size={"sm"}>
            Loading...
          </Spinner>
          &nbsp;
        </>
      ) : (
        ""
      )}
      <Row className='mb-4'>
        <Col lg={12}>
          {props.isEdit ? (
            <div className='d-flex justify-content-end mb-3'>
              <button
                type='button'
                className='btn btn-sm btn-success add-btn me-2'
                id='create-btn'
                onClick={() => {
                  toggle();
                  setType("map");
                  setIsEdit(false);
                }}>
                <i className='ri-map-pin-line me-1 align-bottom fs-14'></i>{" "}
                {t("Setting Map")}
              </button>
              {mapList ? (
                <>
                  <button
                    type='button'
                    className='btn btn-sm btn-warning add-btn me-2'
                    id='create-btn'
                    onClick={() => {
                      handleDashboardDetailClick(mapList);
                    }}>
                    <i className='ri-pencil-line  align-bottom fs-14'></i>
                  </button>
                  <button
                    type='button'
                    className='btn btn-sm btn-danger add-btn'
                    id='create-btn'
                    onClick={() => {
                      const data = mapList;
                      onClickDelete(data);
                    }}>
                    <i className='ri-delete-bin-line  align-bottom fs-14'></i>
                  </button>
                </>
              ) : null}
            </div>
          ) : null}
          {props.mapLists ? (
            <Card style={{ height: "51vh" }}>
              <CardBody>
                <div
                  id='gmaps-markers'
                  className='gmaps'
                  style={{ position: "relative" }}>
                  <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={isOpen ? 12 : 5}
                    center={isOpen ? mapRef : center}
                    options={{
                      mapTypeId: props.mapLists,
                      mapTypeControl: false,
                      fullscreenControl: false,
                      streetViewControl: false,
                    }}
                    onLoad={(map) => setMap(map)}
                    onClick={() => setIsOpen(false)}>
                    {markers
                      ? markers.map((mark, markIdx) => {
                        return (
                          <div key={markIdx}>
                            <Marker
                              position={{
                                lat: parseFloat(mark.latitude),
                                lng: parseFloat(mark.longitude),
                              }}
                              title={mark.name}
                              icon={{
                                url:
                                  mark.device_service &&
                                    mark.device_service.name ===
                                    "Fuel Management System"
                                    ? require("../../assets/images/map-tank.png")
                                    : mark.device_service &&
                                      mark.device_service.name ===
                                      "Power Monitoring"
                                      ? require("../../assets/images/map-power.png")
                                      : mark.device_service &&
                                        mark.device_service.name ===
                                        "Vessel Monitoring System"
                                        ? require("../../assets/images/map-vessel.png")
                                        : mark.device_service &&
                                          mark.device_service.name ===
                                          "Smart Water Monitoring"
                                          ? require("../../assets/images/map-water.png")
                                          : mark.device_service &&
                                            mark.device_service.name ===
                                            "Engine Monitoring"
                                            ? require("../../assets/images/map-engine.png")
                                            : mark.device_service &&
                                              mark.device_service.name ===
                                              "Energy Monitoring System"
                                              ? require("../../assets/images/map-lighting.png")
                                              : mark.device_service &&
                                                mark.device_service.name ===
                                                "Temperature Monitoring"
                                                ? require("../../assets/images/map-weather.png")
                                                : mark.device_service &&
                                                  mark.device_service.name ===
                                                  "Gps Tracking"
                                                  ? require("../../assets/images/map-gps.png")
                                                  : null,
                                scaledSize: new window.google.maps.Size(
                                  32,
                                  50
                                ),
                              }}
                              onClick={() => {
                                handleMarkerClick(
                                  markIdx,
                                  parseFloat(mark.latitude),
                                  parseFloat(mark.longitude),
                                  mark.name
                                );
                                setIsOpen(true);
                              }}>
                              {isOpen && infoWindowData?.id === markIdx ? (
                                <InfoWindow
                                  onCloseClick={() => {
                                    setIsOpen(false);
                                  }}>
                                  <div>
                                    <div className='d-flex'>
                                      <div className='justify-content-start'>
                                        <h3 className='text-primary'>
                                          {mark.name}
                                        </h3>
                                        <p className='m-0'>
                                          Location: {mark.latitude},{" "}
                                          {mark.longitude}
                                        </p>
                                        <p className='m-0'>
                                          UUID: {mark.uid}
                                        </p>
                                        <p className='m-0 mb-3'>
                                          Phone: {mark.phone}
                                        </p>
                                      </div>
                                    </div>
                                    <img
                                      alt=''
                                      src={
                                        mark.image
                                          ? api.MEDIA_URL +
                                          "/media/" +
                                          mark.image
                                          : defaultImg
                                      }
                                      className='img-thumbnail'
                                      width='300'
                                    />
                                  </div>
                                </InfoWindow>
                              ) : null}
                            </Marker>
                          </div>
                        );
                      })
                      : null}
                  </GoogleMap>
                </div>
              </CardBody>
            </Card>
          ) : null}
          <Card>
            <CardBody>
              <div className='d-flex'>
                <div className='avatar-md mt-3 ms-3'>
                  <span className='bg-light'>
                    <img src={device} alt='' height='50px' width='50px' />
                  </span>
                </div>
                <div className='flex-grow-1 ms-3'>
                  <div className='text-center'>
                    <h1 className='mt-4'>{t("Total Devices")}</h1>
                  </div>
                </div>
                <div className='mb-0 text-muted flex-shrink-0'>
                  <div className='d-inline-flex'>
                    <div className='mt-4'>
                      <p className='fs-16 mb-0 text-muted'>
                        <i className='mdi mdi-circle fs-10 align-middle text-info me-1'></i>{" "}
                        {socketActive.length} {t("ON")}
                      </p>
                      <p className='fs-16 mb-0 text-muted'>
                        <i className='mdi mdi-circle fs-10 align-middle text-primary me-1'></i>{" "}
                        {deviceSocket.length - socketActive.length} {t("OFF")}
                      </p>
                    </div>
                    <PortfolioCharts2
                      series={chartData}
                      labelText='Total'
                      labelSize='12px'
                      labelData={["OFF", "ON"]}
                      dataWidth={130}
                      dataColors='["--vz-primary", "--vz-info", "--vz-warning", "--vz-success"]'
                    />
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        {props.isEdit ? (
          <div className='d-flex justify-content-end mb-3'>
            <button
              type='button'
              className='btn btn-sm btn-success add-btn'
              id='create-btn'
              onClick={() => {
                toggle();
                setType("widget");
                setIsEdit(false);
              }}>
              <i className='ri-stack-fill me-1 align-bottom fs-14'></i>{" "}
              {`${t("Add")} ${t("Widget")}`}
            </button>
          </div>
        ) : null}

        {widgetList.length > 0 ? (
          <Masonry columnsCount={4} gutter='25px'>
            {widgetList.map((widget, idxWidget) => {
              return (
                <div key={"widget2" + idxWidget}>
                  <Card className='mb-0'>
                    <CardBody>
                      {props.isEdit ? (
                        <div className='d-flex justify-content-center mb-3'>
                          <button
                            type='button'
                            className='btn btn-sm btn-warning add-btn me-2'
                            id='create-btn'
                            onClick={() => {
                              handleDashboardDetailClick(widget);
                            }}>
                            <i className='ri-pencil-line  align-bottom fs-14'></i>
                          </button>
                          <button
                            type='button'
                            className='btn btn-sm btn-danger add-btn'
                            id='create-btn'
                            onClick={() => {
                              const data = widget;
                              onClickDelete(data);
                            }}>
                            <i className='ri-delete-bin-line  align-bottom fs-14'></i>
                          </button>
                        </div>
                      ) : null}
                      {widget.value === "single" ? (
                        <WidgetSingle data={widget} />
                      ) : widget.value === "list" ? (
                        <WidgetList data={widget} />
                      ) : widget.value === "gauge" ? (
                        <WidgetGauge data={widget} dev={[]} />
                      ) : widget.value === "tank" ? (
                        <WidgetTank
                          data={widget}
                          isEdit={props.isEdit}
                          comp={2}
                        />
                      ) : null}
                    </CardBody>
                  </Card>
                </div>
              );
            })}
            {/* <AlertWidget /> */}
          </Masonry>
        ) : null}
      </Row>
      <ModalInputTemplate
        modal={modal}
        toggle={toggle}
        isEdit={isEdit}
        userType={userType}
        clientId={clientId}
        clientName={clientName}
        companyId={companyId}
        companyName={companyName}
        type={type}
        dashboardTemplateDetail={dashboardTemplateDetail}
        t={t}
      />
      <ToastContainer closeButton={false} />
    </div>
  );
};

Template2.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Template2));
